/* ------------------------------------------------------------------------------
	 Typography
-------------------------------------------------------------------------------*/

@font-face {
   font-family: CapsuulaFont;
   src: url(../fonts/Capsuula.ttf);
}
p {
	font-size: 16px;
	line-height: 22.4px;
	color: #000000;
}
h1 {
	font-size: 65px;
	color: #2d3033;
}
h2 {
	font-size: 40px;
	color: #2d3033;
}
h3 {
	font-size: 28px;
	color: #2d3033;
	font-weight: 300;
}
h4 {
	font-size: 40px;
	color: #000000;
	font-weight: 400;
}
h5 {
	font-size: 14px;
	color: #2d3033;
	text-transform: uppercase;
	font-weight: 700;
}
.btn {
	background-color: #333;
	margin-top: 40px;
	color: #fff;
	font-size: 12px;
	font-weight: 600;
	letter-spacing: 1px;
	border-radius: 0px;
	padding: 3% 7%;
	display: inline-block;
	text-transform: uppercase;
}
.btn:hover, .btn:focus {
	background-color: #000;
	color: #fff;
}
.btn-large {
	padding: 15px 40px;
}
/* ------------------------------------------------------------------------------
	 General Style
-------------------------------------------------------------------------------*/
a {
	color: #e84545;
}
a:hover, a:focus {
	text-decoration: none;
	-moz-transition: background-color, color, 0.3s;
	-o-transition: background-color, color, 0.3s;
	-webkit-transition: background-color, color, 0.3s;
	transition: background-color, color, 0.3s;
}
body {
   font-family: CapsuulaFont, "Open Sans", sans-serif;
   font-weight: 400;
   color: #6c7279;
}
ul, ol {
	margin: 0;
	padding: 0;
}
ul li {
	list-style: none;
}
.section {
	padding: 100px 0;
}
.no-padding {
	padding: 0;
}
.no-gutter [class*=col-] {
	padding-right: 0;
	padding-left: 0;
}
/* ------------------------------------------------------------------------------
	 Header & navigation
-------------------------------------------------------------------------------*/
#header {
	position: fixed;
	width: 94%;
	z-index: 999;
	margin-left: 3%;
	margin-right: 3%;
}
#header .header-content {
	margin: 0 auto;
	width: 94%;
	-moz-transition: padding 0.3s;
	-o-transition: padding 0.3s;
	-webkit-transition: padding 0.3s;
	transition: padding 0.3s;
}
#header .logo {
	font-size: 21px;
   margin-left: -5px;
	color: #000;
	font-weight: 700;
	float: left;
	text-transform: uppercase;
}
#header.fixed {
	background-color: #fff;
}
#header.fixed .header-content {
	border-bottom: 0;
	padding: 25px 0;
}
#header.fixed .nav-toggle {
	top: 18px;
}
.navigation.open {
	opacity: 0.9;
	visibility: visible;
	-moz-transition: opacity 0.5s;
	-o-transition: opacity 0.5s;
	-webkit-transition: opacity 0.5s;
	transition: opacity 0.5s;

}
.navigation {
   margin-top: 40px;
   float: right;
}
.navigation li {
	display: inline-block;
}
.navigation a {
	color: #000;
	font-size: 16px;
	font-weight: 300;
	margin-left: 40px;
	letter-spacing: 2px;
	text-transform: uppercase;
}
.navigation a:hover, .navigation {
	color: #bcbcbc;
}

/* ------------------------------------------------------------------------------
	 Header Slider
-------------------------------------------------------------------------------*/

.carousel {
	width: 89%;
	height: 100%;
	margin: auto;
}
.item, .active, .carousel-inner {
	height: 100%;
}
.carousel-caption {
	bottom: 40%;
	color: #fff;
	left: 15%;
	padding-bottom: 20px;
	padding-top: 20px;
	position: absolute;
	right: 15%;
	text-align: center;
	z-index: 10;
}
.carousel-caption h3 {
	font-family: "Open Sans", sans-serif;
	color: #2d3033;
	text-shadow: none;
	font-size: 31px;
	font-weight: 700;
	text-transform: uppercase;
	letter-spacing: 5px;
}
.carousel-caption p {
	font-family: "Open Sans", sans-serif;
	color: #6c7279;
	text-shadow: none;
	font-size: 13px;
	font-weight: 400;
	letter-spacing: 2px;
}
.carousel-control.left {
	background: none;
}
.carousel-control.right {
	background: none;
}
.carousel-control {
	opacity: 1;
	text-shadow: none;
}
/* ------------------------------------------------------------------------------
	 Ballerina
-------------------------------------------------------------------------------*/
.FirstText {
   width: 89%;
   height: 100%;
   margin: auto;
   background-color: #f2f2f2;
}
   .FirstText h4 {
      font-size: 40px;
      line-height: 33px;
      letter-spacing: 2px;
      font-weight: 400;
      margin-bottom: 30px;
      text-transform: uppercase;
   }
   .FirstText h5 {
      margin-top: 22px;
      font-size: 20px;
      font-weight: 300;
      text-transform: uppercase;
   }
   .FirstText p {
      color: #000000;
      font-size: 16px;
      font-weight: 400;
   }
.FirstText-content {
   text-transform: none;
   margin-left: 100px;
   margin-right: 100px;
}
   .FirstText-content li a {
      color: #6c7279;
      line-height: 30px;
      font-size: 20px;
      margin-top: 50px;
   }
.services-FirstText li a:hover {
   color: #000;
}

/* ------------------------------------------------------------------------------
	 Portfolio
-------------------------------------------------------------------------------*/
#portfolio {
	width: 89%;
	margin: auto;
}
#portfolio .portfolio-item {
	right: 0;
	margin: 0 0 2px;
	padding: 0 1px;
}
#portfolio .portfolio-item .portfolio-link {
	display: block;
	position: relative;
	margin: 0 auto;
}
#portfolio .portfolio-item .portfolio-link .caption {
	position: absolute;
   width: 100%;
   height: 100%;
	opacity: 0;
	background: rgba(58, 54, 54, .9);
	-webkit-transition: all ease .5s;
	-moz-transition: all ease .5s;
	transition: all ease .5s;
}
#portfolio .portfolio-item .portfolio-link .caption:hover {
	opacity: 1;
}
#portfolio .portfolio-item .portfolio-link .caption .caption-content {
	position: absolute;
	top: 50%;
	width: 100%;
	height: 20px;
	margin-top: -12px;
	text-align: center;
	font-size: 20px;
	color: #fff;
}
#portfolio .portfolio-item .portfolio-link .caption .caption-content i {
	margin-top: -12px;
}
#portfolio .portfolio-item .portfolio-link .caption .caption-content h3 {
	margin: 0;
	color: #ffffff;
	font-size: 24px;
	letter-spacing: 4px;
	text-transform: uppercase;
}
#portfolio .portfolio-item .portfolio-link .caption .caption-content h4 {
	margin: 10px 0;
	color: #e9eaed;
	font-size: 12px;
	letter-spacing: 2px;
	text-transform: uppercase;
}
#portfolio * {
	z-index: 2;
}

#portfolio .portfolio-cat
{
	padding: 10px;
}
/* ------------------------------------------------------------------------------
	 Testimonials
-------------------------------------------------------------------------------*/
.testimonials {
	width: 89%;
	margin: auto;
	background-color: #333;
	position: relative;
	text-align: center;
}
.testimonials blockquote {
	border: 0;
	margin: 0;
	padding: 100px 15%;
}
.testimonials h1 {
	color: #fff;
	font: 19px "Open Sans", sans-serif;
	font-weight: 300;
	letter-spacing: 1px;
}
.testimonials p {
	color: #fff;
	display: block;
	font-size: 11px;
	font-style: normal;
	letter-spacing: 2px;
	font-weight: 400;
	margin-top: 30px;
	text-transform: uppercase;
}
/* ------------------------------------------------------------------------------
	 Footer
-------------------------------------------------------------------------------*/
.footer {
	width: 89%;
	margin: auto;
	text-align: left;
	padding-top: 5%;
	padding-bottom: 7%;
}
.footer h4 {
	font-size: 24px;
	color: #000;
	margin: 0 0 15px 0;
	padding: 0px;
}
.footer p {
	font-size: 20px;
	color: #6c7279;
}
   .footer a {
      color: #6c7279;
   }
.footer a:hover {
	text-decoration: underline;
}
.footer .footer-share {
	margin-top: 0;
}
.footer .footer-share li {
	display: inline-block;
	float: none;
}
.footer .footer-share a {
	border: none;
	font-size: 13px;
	color: #000;
	letter-spacing: 15px;
}
.footer .fa-heart {
	color: #e84545;
	font-size: 11px;
	margin: 0 2px;
}
.page-year {
   margin-top: 24px;
}
   /* ------------------------------------------------------------------------------
	 Buttons
-------------------------------------------------------------------------------*/
   .button-list {
      margin-top: 50px;
      text-align: center;
   }

a.button {
   border-radius: 8px;
   background-color: #ffffff;
   border: 1px solid #000000;
   padding: 5px 30px;
   opacity: 90;
   margin: 3px;
   font-size: 16px;
   color: #000000;
   white-space: nowrap;
}

/* ------------------------------------------------------------------------------
	 Kitchen list
-------------------------------------------------------------------------------*/
.kitchen-section {
   margin-top: -70px;
   margin-left: 50px;
   margin-right: 50px;
} 

.kitchen-list {
   margin: 0 50px;
}

.kitchen-list-item:hover {
   opacity: 0.5;
}

.zoom-kitchen:hover{
   background-color: #000000;
   color: #ffffff;
   /*background-image: url(../images/Sources/zoom.svg);*/
   background-repeat: no-repeat;
   background-position: center;
   cursor: pointer;
}

.kitchen-type {
   margin-top: 5px;
   font-size: 24px;
   text-align: center;
}

.map-content
{
	margin-top: 40px;
	margin-left: 130px;
}

.tablecert-content{
	width:100%;
	margin-top: 30px;
}

.kitchencenter
{
	text-align: center;
}

.certImgSize
{
	width:384px;
	height:540px;
}

.Ballerina {
	width: 89%;
	height: 100%;
	margin: auto;
	background-color: #f2f2f2;
 }
	.Ballerina h4 {
	   font-size: 40px;
	   line-height: 33px;
	   letter-spacing: 2px;
	   font-weight: 400;
	   margin-bottom: 30px;
	   text-transform: uppercase;
	}
	.Ballerina h5 {
	   margin-top: 22px;
	   font-size: 20px;
	   font-weight: 300;
	   text-transform: uppercase;
	}
	.Ballerina p {
	   color: #000000;
	   font-size: 16px;
	   font-weight: 400;
	}
 .Ballerina-content {
	text-transform: none;
	margin-left: 100px;
 }
	.Ballerina-content li a {
	   color: #6c7279;
	   line-height: 30px;
	   font-size: 20px;
	   margin-top: 50px;
	}
 .services-Ballerina li a:hover {
	color: #000;
 }
 .retry-logo {
	margin-right: 50px;
	float: right;
	opacity: 0.9
 }
 
	.retry-logo a {
	   font-size: 15px;
	}
.facebookcenter
{
   text-align: center;
}

/* ------------------------------------------------------------------------------
	 Responsive menu
-------------------------------------------------------------------------------*/
  nav {
	position: relative;
	width: 980px;
	margin: 0 auto;
  }
  #cssmenu,
  #cssmenu ul,
  #cssmenu ul li,
  #cssmenu ul li a,
  #cssmenu #head-mobile {
	border: 0;
	list-style: none;
	line-height: 1;
	display: block;
	position: relative;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
  }
  #cssmenu:after,
  #cssmenu > ul:after {
	content: ".";
	display: block;
	clear: both;
	visibility: hidden;
	line-height: 0;
	height: 0;
  }
  #cssmenu #head-mobile {
	display: none;
  }
  #cssmenu {
	font-family:CapsuulaFont, "Open Sans", sans-serif;
	float: right;
	width: 100%;
  }
  #cssmenu > ul > li {
	float: left;
  }
  #cssmenu > ul > li > a {
	padding: 17px;
	font-size: 16px;
	letter-spacing: 1px;
	text-decoration: none;
	 line-height: 23px;
	 color: black;
	font-weight: 300;
  }
  #cssmenu > ul > li:hover > a,
  #cssmenu ul li.active a {
	color: #bcbcbc;
  }
  #cssmenu > ul > li:hover,
  #cssmenu ul li.active:hover,
  #cssmenu ul li.active,
  #cssmenu ul li.has-sub.active:hover {
	-webkit-transition: background 0.3s ease;
	-ms-transition: background 0.3s ease;
	transition: background 0.3s ease;
  }
  #cssmenu > ul > li.has-sub > a {
	padding-right: 30px;
  }
  #cssmenu > ul > li.has-sub > a:after {
	position: absolute;
	top: 28px;
	right: 11px;
	width: 8px;
	height: 2px;
	display: block;
	background: #ddd;
	content: "";
  }
  #cssmenu > ul > li.has-sub > a:before {
	position: absolute;
	top: 25px;
	right: 14px;
	display: block;
	width: 2px;
	height: 8px;
	background: #ddd;
	content: "";
	-webkit-transition: all 0.25s ease;
	-ms-transition: all 0.25s ease;
	transition: all 0.25s ease;
  }
  #cssmenu > ul > li.has-sub:hover > a:before {
	top: 23px;
	height: 0;
  }
  #cssmenu ul ul {
	position: absolute;
	left: -9999px;
  }
  #cssmenu ul ul li {
	height: 0;
	-webkit-transition: all 0.25s ease;
	-ms-transition: all 0.25s ease;
	background: #333;
	transition: all 0.25s ease;
  }
  #cssmenu ul ul li:hover {
  }
  #cssmenu li:hover > ul {
	left: auto;
  }
  #cssmenu li:hover > ul > li {
	height: 35px;
  }
  #cssmenu ul ul ul {
	margin-left: 100%;
	top: 0;
  }
  #cssmenu ul ul li a {
	border-bottom: 1px solid rgba(150, 150, 150, 0.15);
	padding: 11px 15px;
	width: 170px;
	font-size: 16px;
	text-decoration: none;
	color: #ddd;
	font-weight: 300;
  }
  #cssmenu ul ul li:last-child > a,
  #cssmenu ul ul li.last-item > a {
	border-bottom: 0;
  }
  #cssmenu ul ul li:hover > a,
  #cssmenu ul ul li a:hover {
	color: #fff;
  }
  #cssmenu ul ul li.has-sub > a:after {
	position: absolute;
	top: 16px;
	right: 11px;
	width: 8px;
	height: 2px;
	display: block;
	background: #ddd;
	content: "";
  }
  #cssmenu ul ul li.has-sub > a:before {
	position: absolute;
	top: 13px;
	right: 14px;
	display: block;
	width: 2px;
	height: 8px;
	background: #ddd;
	content: "";
	-webkit-transition: all 0.25s ease;
	-ms-transition: all 0.25s ease;
	transition: all 0.25s ease;
  }
  #cssmenu ul ul > li.has-sub:hover > a:before {
	top: 17px;
	height: 0;
  }
  #cssmenu ul ul li.has-sub:hover,
  #cssmenu ul li.has-sub ul li.has-sub ul li:hover {
	background: #363636;
  }
  #cssmenu ul ul ul li.active a {
	border-left: 1px solid #333;
  }
  #cssmenu > ul > li.has-sub > ul > li.active > a,
  #cssmenu > ul ul > li.has-sub > ul > li.active > a {
	border-top: 1px solid #333;
  }
  
  @media screen and (max-width: 1000px) {
	.logo {
	  position: absolute;
	  top: 0;
	  left: 0;
	  width: 100%;
	  height: 46px;
	  text-align: center;
	  padding: 10px 0 0 0;
	  float: none;
	}
	.logo2 {
	  display: none;
	}
	nav {
	  width: 100%;
	}
	#cssmenu {
	  width: 100%;
	}
	#cssmenu ul {
	  width: 100%;
	  display: none;
	}
	#cssmenu ul li {
	  width: 100%;
	  border-top: 1px solid #444;
	}
	#cssmenu ul li:hover {
	  background: #363636;
	}
	#cssmenu ul ul li,
	#cssmenu li:hover > ul > li {
	  height: auto;
	}
	#cssmenu ul li a,
	#cssmenu ul ul li a {
	  width: 100%;
	  border-bottom: 0;
	}
	#cssmenu > ul > li {
	  float: none;
	}
	#cssmenu ul ul li a {
	  padding-left: 25px;
	}
	#cssmenu ul ul li {
	  background: #333 !important;
	}
	#cssmenu ul ul li:hover {
	  background: #363636 !important;
	}
	#cssmenu ul ul ul li a {
	  padding-left: 35px;
	}
	#cssmenu ul ul li a {
	  color: #ddd;
	  background: none;
	}
	#cssmenu ul ul li:hover > a,
	#cssmenu ul ul li.active > a {
	  color: #fff;
	}
	#cssmenu ul ul,
	#cssmenu ul ul ul {
	  position: relative;
	  left: 0;
	  width: 100%;
	  margin: 0;
	  text-align: left;
	}
	#cssmenu > ul > li.has-sub > a:after,
	#cssmenu > ul > li.has-sub > a:before,
	#cssmenu ul ul > li.has-sub > a:after,
	#cssmenu ul ul > li.has-sub > a:before {
	  display: none;
	}
	#cssmenu #head-mobile {
	  display: block;
	  padding: 23px;
	  color: #ddd;
	  font-size: 16px;
	  font-weight: 300;
	}
	.button {
	  width: 55px;
	  height: 46px;
	  position: absolute;
	  right: 0;
	  top: 0;
	  cursor: pointer;
	  z-index: 12399994;
	}
	.button:after {
	  position: absolute;
	  top: 22px;
	  right: 20px;
	  display: block;
	  height: 4px;
	  width: 20px;
	  border-top: 2px solid #bcbcbc;
	  border-bottom: 2px solid #bcbcbc;
	  content: "";
	}
	.button:before {
	  -webkit-transition: all 0.3s ease;
	  -ms-transition: all 0.3s ease;
	  transition: all 0.3s ease;
	  position: absolute;
	  top: 16px;
	  right: 20px;
	  display: block;
	  height: 2px;
	  width: 20px;
	  background: #bcbcbc;
	  content: "";
	}
	.button.menu-opened:after {
	  -webkit-transition: all 0.3s ease;
	  -ms-transition: all 0.3s ease;
	  transition: all 0.3s ease;
	  top: 23px;
	  border: 0;
	  height: 2px;
	  width: 19px;
	  background: #bcbcbc;
	  -webkit-transform: rotate(45deg);
	  -moz-transform: rotate(45deg);
	  -ms-transform: rotate(45deg);
	  -o-transform: rotate(45deg);
	  transform: rotate(45deg);
	}
	.button.menu-opened:before {
	  top: 23px;
	  background: #bcbcbc;
	  width: 19px;
	  -webkit-transform: rotate(-45deg);
	  -moz-transform: rotate(-45deg);
	  -ms-transform: rotate(-45deg);
	  -o-transform: rotate(-45deg);
	  transform: rotate(-45deg);
	}
	#cssmenu .submenu-button {
	  position: absolute;
	  z-index: 99;
	  right: 0;
	  top: 0;
	  display: block;
	  border-left: 1px solid #444;
	  height: 46px;
	  width: 46px;
	  cursor: pointer;
	}
	#cssmenu .submenu-button.submenu-opened {
	  background: #262626;
	}
	#cssmenu ul ul .submenu-button {
	  height: 34px;
	  width: 34px;
	}
	#cssmenu .submenu-button:after {
	  position: absolute;
	  top: 22px;
	  right: 19px;
	  width: 8px;
	  height: 2px;
	  display: block;
	  background: #ddd;
	  content: "";
	}
	#cssmenu ul ul .submenu-button:after {
	  top: 15px;
	  right: 13px;
	}
	#cssmenu .submenu-button.submenu-opened:after {
	  background: #fff;
	}
	#cssmenu .submenu-button:before {
	  position: absolute;
	  top: 19px;
	  right: 22px;
	  display: block;
	  width: 2px;
	  height: 8px;
	  background: #ddd;
	  content: "";
	}
	#cssmenu ul ul .submenu-button:before {
	  top: 12px;
	  right: 16px;
	}
	#cssmenu .submenu-button.submenu-opened:before {
	  display: none;
	}
	#cssmenu ul ul ul li.active a {
	  border-left: none;
	}
	#cssmenu > ul > li.has-sub > ul > li.active > a,
	#cssmenu > ul ul > li.has-sub > ul > li.active > a {
	  border-top: none;
	}
	}
	
	.ul-menu{
		float: right;
		margin-top: 24px;
	}
  

